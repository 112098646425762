import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownList from "../molecules/DropdownList";

interface MultiSelectDropdownProps {
  optionsArray: string[];
  text: string;
  onSelectionChange: (selectedItems: string[]) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ optionsArray, onSelectionChange, text }) => {
  // Deduplicate options using a Set to maintain unique entries
  const uniqueOptions: string[] = Array.from(new Set(optionsArray));
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Handle selection toggle
  const handleSelection = (option: string) => {
    let updatedSelections: string[];
    if (selectedItems.includes(option)) {
      updatedSelections = selectedItems.filter((item) => item !== option);
    } else {
      updatedSelections = [...selectedItems, option];
    }
    setSelectedItems(updatedSelections);
    onSelectionChange(updatedSelections); // Trigger callback on every change
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="multi-select-dropdown">
       {text}
      </Dropdown.Toggle>

      <DropdownList
        options={uniqueOptions}
        selectedItems={selectedItems}
        handleSelection={handleSelection}
      />
    </Dropdown>
  );
};

export default MultiSelectDropdown;