import React from "react";
import { Form } from "react-bootstrap";

interface OptionItemProps {
  option: string;
  isChecked: boolean;
  handleSelection: (option: string) => void;
}

const OptionItem: React.FC<OptionItemProps> = ({ option, isChecked, handleSelection }) => {
  return (
    <Form.Check
      type="checkbox"
      id={`multi-select-${option}`}
      label={option}
      checked={isChecked}
      onChange={() => handleSelection(option)}
    />
  );
};

export default OptionItem;