
import React from 'react';
import { InputGroup, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

interface InputFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string
  label?: string
  name?: string
  required?: boolean
  disabled?: boolean
}

const InputField: React.FC<InputFieldProps> = ({ value, onChange, placeholder, type="text", label, required=false, name, disabled=false }) => {
  return (
    <InputGroup className="mb-3">
     {label && <InputGroup.Text>{label}</InputGroup.Text>}
      <FormControl
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        name={name}
        disabled={disabled}
      />
    </InputGroup>
  );
};

export default InputField;