import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

// TypeScript interface matching the structure of stored data
interface HealthData {
  heartRate: number;
  respiratoryRate: number;
  temperature: number;
  weight: number;
  timestamp: string;
  machine_id: string;
}

const ExportDataButton: React.FC = () => {
  const [hasData, setHasData] = useState(false);

  // Check local storage for data on component mount and periodically
  useEffect(() => {
    // Define a function to check for data
    const checkForData = () => {
      const storedData = localStorage.getItem("healthData");
      setHasData(!!storedData); // Update state based on presence of data
    };

    // Initial check
    checkForData();

    // Set up an interval to check for data every 5 seconds
    const intervalId = setInterval(checkForData, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const exportDataToCSV = () => {
    const storedData = localStorage.getItem("healthData");
    if (!storedData) {
      alert("No data available to export.");
      return;
    }
  
    const healthDataList: HealthData[] = JSON.parse(storedData);
  
    // Create CSV header
    const csvHeaders = ["Machine ID", "Heart Rate", "Respiratory Rate", "Temperature", "Weight", "Timestamp"];
  
    // Map data to CSV rows with each value wrapped in double quotes to handle commas
    const csvRows = healthDataList.map(data => [
      `"${data.machine_id}"`,
      data.heartRate !== -1 ? `"${data.heartRate}"` : '""',
      data.respiratoryRate !== -1 ? `"${data.respiratoryRate}"` : '""',
      data.temperature !== -1 ? `"${data.temperature}"` : '""',
      data.weight !== -1 ? `"${data.weight}"` : '""',
      `"${data.timestamp}"`
    ]);
  
    // Convert to CSV string
    const csvContent = [
      csvHeaders.join(","), // CSV header row
      ...csvRows.map(row => row.join(",")), // CSV data rows
    ].join("\n");
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    // Create a link to trigger download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `health_data_${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  
    // Clear data from local storage and disable button
    localStorage.removeItem("healthData");
    setHasData(false); // Immediately disable the button after data is cleared
    alert("Data exported and cleared from local storage.");
  };

  return (
    <Button variant="success" onClick={exportDataToCSV} disabled={!hasData}>
      Export Data to CSV
    </Button>
  );
};

export default ExportDataButton;