import React from 'react';
import _Button  from 'react-bootstrap/Button';

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  href?: string
  text?: string
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ type = 'button', onClick, children, className, href, text }) => {
  return (
    <_Button type={type} className={className} onClick={onClick} href={href}>
      {children || text}
    </_Button>
  );
};

export default Button;
