import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState } from '../../redux/slices/authSlice';
import { Badge, Patient, Session, Sex, Device } from '../../types';
import PatientCard from '../organisms/PatientCard';
import { fetchDevicesWithVitals, selectDevices, selectDevicesLoading, selectDevicesError } from '../../redux/slices/devices';
import websocketService from '../../services/websocketService';
import MultiSelectDropdown from '../organisms/MultiSelectDropdown';
import HealthDataOverlay from '../organisms/HealthDataManager';
import ExportDataButton from "../molecules/ExportDataButton";

const ALL = "all";

const defaultSession: Session = {
  id: "",
  startTime: ""
}

const defaultPatient: Patient = {
  id: "",
  customerId: "--",
  species: '--',
  breed: 'house',
  name: "--",
  sex: Sex.FEMALE_SPAYED,
  doctor: '--',
  phone: '--',
}
const defaultBadge: Badge = {
  color: "pink",
  number: "--",
  location: "--"
}
const VitalsBoardPage: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [activeMachineId, setActiveMachineId] = useState<string | null>(null);
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const devices = useSelector(selectDevices);
  const loading = useSelector(selectDevicesLoading);
  const error = useSelector(selectDevicesError);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedLocations, setLocations] = useState<undefined | string[]>([ALL])
  const openOverlay = (machine_id: string) => setActiveMachineId(machine_id);
  const closeOverlay = () =>{
    setActiveMachineId(null);
    setShowOverlay(false)
  } 
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchDevicesWithVitals());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && devices.length < 1 && !user) {
      return;
    }
    if (user && !user.token) {
      return;
    }
    const jwt = user?.token || "--"
    const roomIds = devices.map((device) => device.id)
    websocketService.connect(jwt);

    roomIds.forEach(roomId => {
      websocketService.joinRoom(roomId);
    });

    return () => {
      websocketService.disconnect();
    };
  }, [isAuthenticated, user, devices]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (selectedDevices.length > 0) {
      return;
    }
    setSelectedDevices(devices)

  }, [isAuthenticated, devices, selectedDevices])

  useEffect(() => {
    if (selectedLocations?.indexOf(ALL) !== -1) {
      setSelectedDevices(devices)
      return;
    }
    const unified = selectedLocations.map((str) => str.toLowerCase());
    const choosenDevices = devices.filter((device) => {
      if(!device.inClinicLocation){
        return false
      } else {
        return unified.indexOf(device.inClinicLocation.toLocaleLowerCase())  !== -1
      }
    })
    setSelectedDevices(choosenDevices)

  }, [selectedLocations])


  if (!isAuthenticated || loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }
  const handleSelectionChange = (selectedItems: string[]): void => {
    setLocations(selectedItems)
  };

  const locations = [ALL, ...new Set(devices.map(device => (device.inClinicLocation || "--")))]
  return (
    <>
      <br />
      <Row>
        <MultiSelectDropdown text="Show pads" optionsArray={locations} onSelectionChange={handleSelectionChange}/>
      </Row>
      <br />
      <Row>
        {selectedDevices.map((device) => {
          const session = device?.session || {...defaultSession}
          const aPatient = session?.patient || {...defaultPatient};
          const aBadge = {...defaultBadge};
          if (device.colorCode) {
            aBadge.color = device.colorCode;
            aBadge.location = device.colorCode;
          }
          if (device.inClinicLocation) {
            aBadge.location = device.inClinicLocation;
          }
          if (device.name) {
            aBadge.location = device.name;
          }
          if (device.inClinicLocationNumber) {
            aBadge.number = `${device.inClinicLocationNumber}`;
          }
          return (
            <React.Fragment key={device.id}>
              <Col md={4} className="mb-4">
                <PatientCard
                  sessionId={session.id}
                  patient={aPatient}
                  badge={aBadge}
                  deviceId={device.id}
                  onOpenOverlay={(machine_id) => {
                    setShowOverlay(true)
                    openOverlay(machine_id)
                  }}
                />
              </Col>
            </React.Fragment>
          )
        })}
      </Row>
      <Row>
        <ExportDataButton />
      </Row>
      <HealthDataOverlay machine_id={activeMachineId} onClose={closeOverlay } show={showOverlay}/>
      </>
  );
};

export default VitalsBoardPage;
