import React, { useState } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

// Define the structure of the form data using a TypeScript interface
interface HealthData {
  heartRate: number;
  respiratoryRate: number;
  temperature: number;
  weight: number;
  timestamp: string;
  machine_id: string | null;
}

interface HealthDataOverlayProps {
  machine_id: string | null;
  onClose?: () => void;
  show: boolean
}

const HealthDataOverlay: React.FC<HealthDataOverlayProps> = ({ machine_id, onClose, show = false }) => {

  // State to manage the form inputs
  const [heartRate, setHeartRate] = useState<number | string>("");
  const [respiratoryRate, setRespiratoryRate] = useState<number | string>("");
  const [temperature, setTemperature] = useState<number | string>("");
  const [weight, setWeight] = useState<number | string>("");

  // Open and close the modal functions
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  // Function to handle form submission
  const handleSubmit = () => {
    const healthData: HealthData = {
      heartRate: heartRate !== "" ? Number(heartRate) : -1,
      respiratoryRate: respiratoryRate !== "" ? Number(respiratoryRate) : -1,
      temperature: temperature !== "" ? Number(temperature) : -1,
      weight: weight !== "" ? Number(weight) : -1,
      timestamp: new Date().toLocaleString(), // Timestamp in local time format
      machine_id, // Save the machine_id with the data
    };

    // Get any existing data from local storage and parse it
    const storedData = localStorage.getItem("healthData");
    const healthDataList: HealthData[] = storedData ? JSON.parse(storedData) : [];

    // Add the new data entry to the list and save back to local storage
    healthDataList.push(healthData);
    localStorage.setItem("healthData", JSON.stringify(healthDataList));

    // Reset form and close the modal
    setHeartRate("");
    setRespiratoryRate("");
    setTemperature("");
    setWeight("");
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Health Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formHeartRate">
                    <Form.Label>Heart Rate</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter heart rate"
                      value={heartRate}
                      onChange={(e) => setHeartRate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formRespiratoryRate">
                    <Form.Label>Respiratory Rate</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter respiratory rate"
                      value={respiratoryRate}
                      onChange={(e) => setRespiratoryRate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formTemperature">
                    <Form.Label>Temperature</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter temperature"
                      value={temperature}
                      onChange={(e) => setTemperature(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formWeight">
                    <Form.Label>Weight</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HealthDataOverlay;