import React from 'react';
import styled from 'styled-components';
import { Badge, Container, Row, Col } from 'react-bootstrap';
import { PadColerElement, PadColorKeys, padColors } from '../../theme/colors';

interface BadgeProps {
  textColor?: string;
  bgColor?: string;
}

interface BadgeComponentProps {
  number?: string;
  color?: PadColorKeys | string;
  location?: string
}

interface SubTextProps {
  displaycolor: string;
}

const SubText = styled.span<SubTextProps>`
  color: ${props => props.displaycolor};
`;

const LargeBadge = styled(Badge) <BadgeProps>`
  font-size: 1.0em;
  color: ${props => props.textColor};
  background-color: ${props => props.bgColor} !important;
  @media (min-width: 576px) {
    font-size: 1em;
  }
  @media (min-width: 768px) {
    font-size: 1.25em;
  }
  @media (min-width: 992px) {
    font-size: 1.5em;
  }
  @media (min-width: 1200px) {
    font-size: 2em;
  }
  @media (min-width: 1400px) {
    font-size: 2em;
  }
`;

function getPadColor(key: PadColorKeys): PadColerElement {
  return padColors[key];
}

const SpanBadgeId = styled.span`
  color: #000000;
  font-size: 1.5em;
`


const BadgeComponent: React.FC<BadgeComponentProps> = ({ number = "--", color = "pink", location }) => {
  const colorPallet = getPadColor(color as PadColorKeys);
  return (
    <LargeBadge pill={false} bgColor={colorPallet.bg} >
      <Container>
        <Row>
          <Col><SpanBadgeId>{number}</SpanBadgeId></Col>
        </Row>
        <Row>
          <Col><SubText displaycolor={colorPallet.subtext}>{location}</SubText></Col>
        </Row>
      </Container>
    </LargeBadge>
  )
};

export default BadgeComponent;
