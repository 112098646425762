import React from "react";
import { Dropdown } from "react-bootstrap";
import OptionItem from "../atoms/OptionItem";

interface DropdownListProps {
  options: string[];
  selectedItems: string[];
  handleSelection: (option: string) => void;
}

const DropdownList: React.FC<DropdownListProps> = ({ options, selectedItems, handleSelection }) => {
  return (
    <Dropdown.Menu show>
      {options.map((option) => (
        <Dropdown.Item key={option} as="div">
          <OptionItem
            option={option}
            isChecked={selectedItems.includes(option)}
            handleSelection={handleSelection}
          />
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};

export default DropdownList;