
// src/redux/slices/dataSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { VitalsDTO } from '../../types';

interface DataState {
  [roomId: string]: VitalsDTO
}

const defaultDataState:VitalsDTO = {
  ts: 0,
  hr: -1,
  avgHR: -1,
  actHR: -1,
  rr: -1,
  avgRR: -1,
  actRR: -1,
  gt: {
    hr: -1,
    rr: -1
  },
  stale: true,
  temp: -1,
  session_id: '',
  machine_id: '',
  weight: -1,
  validTemp: false,
  meta: {
    continuousData: null,
    gt: false,
    clippedVibration: null,
    significateWeight: null,
    weightSettled: null,
    validVibration: false,
    heartbeat: false,
    vhrValid: false,
    vrrValid: false,
    wrrValid: false,
    vrr: -1
  }
} 

const initialState: DataState = {};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateData(state, action: PayloadAction<{ roomId: string, data: Partial<DataState[string]> }>) {
      const { roomId, data } = action.payload;
      if (!state[roomId]) {
        state[roomId] = {...defaultDataState, machine_id: roomId };
      }
      state[roomId] = { ...state[roomId], ...data };
    },
  },
});

export const { updateData } = dataSlice.actions;

export const selectRoomData = (state: RootState, roomId: string) => state.data[roomId] || defaultDataState;

export default dataSlice.reducer;
