// src/components/organisms/AccountForm.tsx
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import InputField from '../atoms/InputField';
import SelectField from '../atoms/SelectField';
import Button from '../atoms/Button';
import { User, updateDisplayName } from '../../redux/slices/authSlice';
import { updateProfile, User as FirebaseUser } from 'firebase/auth';
import { useDispatch } from 'react-redux';


interface AccountFormProps {
  user: User;
}

const AccountForm: React.FC<AccountFormProps> = ({ user }) => {
  const dispatch = useDispatch<any>()
  const [name, setName] = useState(user.displayName || "--")
  
  const updateFirebase = async (user:FirebaseUser, name:string) =>{
    await updateProfile(user, {
      displayName: name,
    });
  }

  const handleSubmit = async () => {
    // Handle form submission (e.g., send data to backend)
    console.log(name);
    if (user.currentUser && (name !== user.displayName)) {
      updateFirebase(user.currentUser, name);
      dispatch(updateDisplayName(name));
    }
  };

  return (<>
    <InputField
      label="Name"
      type="text"
      name="displayName"
      value={name}
      required
      onChange={(e) => setName(e.target.value)}
    />
    <InputField
      label="Email"
      type="email"
      name="email"
      value={user.email || "--"}
      disabled
      required
      onChange={()=>{}}
    />
    <Button text="Save Changes" type="submit" onClick={handleSubmit}/></>
  );
};

export default AccountForm;