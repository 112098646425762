import React, { useState, useEffect, useRef } from 'react';
import { VitalsColorKeys, vitalColors } from '../../theme/colors';
import styled from 'styled-components';

// Define the prop types for the component
interface TimedValueComponentProps {
  value: number;
  valid: boolean;
  small?: boolean
  unit: string;
  displayColor?: VitalsColorKeys;
  precision?: number
}

interface StyledSpanProps {
  displaycolor: VitalsColorKeys | string;
  small: boolean;
}

interface StyledSmallProps {
  displaycolor: VitalsColorKeys | string;
}

const SmallText = styled.small<StyledSmallProps>`
  color: ${props => props.displaycolor};
`;

const Text = styled.span<StyledSpanProps>`
  font-size: ${props => (props.small ? '1.5' : '2')}em;
  color: ${props => props.displaycolor};
  @media (min-width: 576px) {
    font-size: ${props => (props.small ? '1.5' : '2.5')}em;
  }
  @media (min-width: 768px) {
    font-size: ${props => (props.small ? '2.25' : '3')}em;
  }
  @media (min-width: 992px) {
    font-size: ${props => (props.small ? '2.75' : '3.5')}em;
  }
  @media (min-width: 1200px) {
    font-size: ${props => (props.small ? '3' : '4')}em;
  }
  @media (min-width: 1400px) {
    font-size: ${props => (props.small ? '3.75' : '4.5')}em;
  }
`;

const TimedValueComponent: React.FC<TimedValueComponentProps> = ({ value, valid, displayColor="white", small=false, unit, precision }) => {
  const [displayValue, setDisplayValue] = useState<string | number>('--');
  const [color, setColor] = useState<VitalsColorKeys>('grey');
  let toFixed = 2;
  if(precision !== undefined){
    toFixed = precision
  }
  
  // Use refs to keep track of the timers
  const greyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const resetValueTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearTimers = () => {
    if (greyTimeoutRef.current) clearTimeout(greyTimeoutRef.current);
    if (resetValueTimeoutRef.current) clearTimeout(resetValueTimeoutRef.current);
  };

  useEffect(() => {
    if (valid && value !== -1 && value) {
      setDisplayValue(value);
      setColor(displayColor);


      // Clear any existing timers to start fresh
      clearTimers();

      // Set a timer to change color to grey after 5 minutes
      greyTimeoutRef.current = setTimeout(() => {
        setColor('grey');
      }, 5 * 60 * 1000);

      // Set a timer to reset the value to "--" after 10 minutes
      resetValueTimeoutRef.current = setTimeout(() => {
        setDisplayValue('--');
      }, 10 * 60 * 1000);
    }
  }, [value, valid, displayColor]);

  useEffect(() => {
    return () => {
      // Clean up timers when the component unmounts
      clearTimers();
    };
  }, []);

  return (
    <><Text displaycolor={vitalColors[color]} small={small}>
      {typeof displayValue === 'number' ? displayValue.toFixed(toFixed) : displayValue}
    </Text>
    <SmallText displaycolor={vitalColors[color]}>{unit}</SmallText>
    </>
  );
};

export default TimedValueComponent;