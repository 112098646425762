// src/components/pages/AccountPage.tsx
import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import AccountForm from '../organisms/AccountForm';
import { RootState } from '../../redux/rootReducer'
import { useSelector } from 'react-redux';

const AccountPage: React.FC = () => {
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth); 
  return <Container className="mt-5">
    <Row className="justify-content-md-center">
      <Col md={6}>
        <h2 className="text-center mb-4">My Account</h2>
        {user && <AccountForm user={user} />}
        {!user && <Spinner animation="border" variant="primary" />}
      </Col>
    </Row>
  </Container>
};

export default AccountPage;